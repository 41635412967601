/* eslint-disable max-classes-per-file */
import { Base } from '@studiometa/js-toolkit';
import Flickity from 'flickity-fade';
// import Flickity from 'flickity';
import isDev from '../utils/is-dev';

/**
 * Modal class.
 */
export default class PSCategoryProducts extends Base {
  /**
   * Modal config.
   * @return {Object}
   */
  get config() {
    return {
      name: 'PSCategoryProducts',
      log: isDev(),
    };
  }

  /**
   * Initialize the component's behaviours.
   *
   * @return {Modal} The current instance.
   */
  mounted() {
    this.handleClickSliderPrevious = this.handleClickSliderPrevious.bind(this);
    this.handleClickSliderNext = this.handleClickSliderNext.bind(this);

    this.slider = new Flickity(this.$refs.slider, {
      cellAlign: 'left',
      contain: true,
      wrapAround: true,
      draggable: true,
      lazyLoad: true,
      prevNextButtons: false,
      pageDots: false,
      on: {
        ready: () => {
          setTimeout(() => {
            this.$refs.slider.classList.add('is-flickity-ready');
          }, 150);
        },
      },
    });

    this.$refs.sliderArrowPrevious.addEventListener('click', this.handleClickSliderPrevious);
    this.$refs.sliderArrowNext.addEventListener('click', this.handleClickSliderNext);

    return this;
  }

  /**
   * Event click previous slider
   */
  handleClickSliderPrevious() {
    this.slider.previous();
  }

  /**
   * Event click next slider
   */
  handleClickSliderNext() {
    this.slider.next();
  }

  /**
   * Destroy
   */
  destroy() {
    if (this.slider) {
      this.$refs.slider.classList.remove('is-flickity-ready');
      this.slider.destroy();
      this.slider = null;

      this.$refs.sliderArrowPrevious.removeEventListener('click', this.handleClickSliderPrevious);
      this.$refs.sliderArrowNext.removeEventListener('click', this.handleClickSliderNext);
    }
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
