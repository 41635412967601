/* eslint-disable max-classes-per-file */
import { Base } from '@studiometa/js-toolkit';
import Flickity from 'flickity-fade';
// import Flickity from 'flickity';
import isDev from '../utils/is-dev';

/**
 * Modal class.
 */
export default class PSCategoryTree extends Base {
  /**
   * Modal config.
   * @return {Object}
   */
  get config() {
    return {
      name: 'PSCategoryTree',
      log: isDev(),
    };
  }

  /**
   * Initialize the component's behaviours.
   *
   * @return {Modal} The current instance.
   */
  mounted() {
    this.initOrDestroySliders();

    return this;
  }

  /**
   * Init or destroy sliders depending screen size
   */
  initOrDestroySliders() {
    if (window.matchMedia('(min-width: 768px)').matches) {
      this.destroy();
    } else {
      this.init();
    }
  }

  /**
   * Init or destroy sliders depending screen size
   */
  init() {
    if (this.sliderMiniatures && this.sliderNames) {
      return this;
    }

    this.sliderMiniatures = new Flickity(this.$refs.sliderMiniatures, {
      cellAlign: 'center',
      contain: true,
      draggable: true,
      fade: true,
      prevNextButtons: false,
      pageDots: false,
      on: {
        ready: () => {
          setTimeout(() => {
            this.$refs.sliderMiniatures.classList.add('is-flickity-ready');
          }, 150);
        },
        change: () => {
          if (
            this.sliderNames &&
            this.sliderMiniatures.selectedIndex !== this.sliderNames.selectedIndex
          ) {
            this.sliderNames.select(this.sliderMiniatures.selectedIndex);
            this.$refs.btn.href =
              ((this.sliderMiniatures.selectedElement || {}).querySelector('a') || {}).href || '#';
          }
        },
      },
    });

    this.sliderNames = new Flickity(this.$refs.sliderNames, {
      cellAlign: 'center',
      contain: true,
      draggable: true,
      prevNextButtons: false,
      pageDots: false,
      on: {
        ready: () => {
          setTimeout(() => {
            this.$refs.sliderNames.classList.add('is-flickity-ready');
          }, 150);
        },
        change: () => {
          if (
            this.sliderMiniatures &&
            this.sliderNames.selectedIndex !== this.sliderMiniatures.selectedIndex
          ) {
            this.sliderMiniatures.select(this.sliderNames.selectedIndex);
            this.$refs.btn.href =
              ((this.sliderNames.selectedElement || {}).querySelector('a') || {}).href || '#';
          }
        },
      },
    });

    return this;
  }

  /**
   * Resized
   */
  resized() {
    this.initOrDestroySliders();
  }

  /**
   * Destroy
   */
  destroy() {
    if (this.sliderMiniatures) {
      this.$refs.sliderMiniatures.classList.remove('is-flickity-ready');
      this.sliderMiniatures.destroy();
      this.sliderMiniatures = null;
    }

    if (this.sliderNames) {
      this.$refs.sliderNames.classList.remove('is-flickity-ready');
      this.sliderNames.destroy();
      this.sliderNames = null;
    }
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
