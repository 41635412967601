/* eslint-disable max-classes-per-file,class-methods-use-this */
import { Base } from '@studiometa/js-toolkit';
import isDev from '../utils/is-dev';

/**
 * Modal class.
 */
export default class PSLanguageSelector extends Base {
  /**
   * Modal config.
   * @return {Object}
   */
  get config() {
    return {
      name: 'PSLanguageSelector',
      log: isDev(),
    };
  }

  /**
   * Initialize the component's behaviours.
   *
   * @return {Modal} The current instance.
   */
  mounted() {
    if (!this.$refs.select) {
      return this;
    }

    this.$refs.select.addEventListener('change', this.handleChangeSelect);

    return this;
  }

  /**
   * Event change previous slider
   */
  handleChangeSelect(event) {
    const url = event.target.value || '';
    if (!url) {
      return;
    }

    window.location.href = url;
  }

  /**
   * Destroy
   */
  destroy() {
    if (!this.$refs.select) {
      return;
    }

    this.$refs.select.removeEventListener('change', this.handleChangeSelect);
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
