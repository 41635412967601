/* eslint-disable class-methods-use-this */
import { Base } from '@studiometa/js-toolkit';
import isDev from '../utils/is-dev';

/**
 * Header class.
 */
export default class Header extends Base {
  /**
   * Header config.
   * @return {Object}
   */
  get config() {
    return {
      name: 'Header',
      log: isDev(),
    };
  }

  /**
   * Initialize the component's behaviours.
   *
   * @return {Header} The current instance.
   */
  mounted() {
    this.scrollHistory = [0, 0];

    this.$refs.btn.addEventListener('click', this.toggle);

    if (window.scrollY) {
      window.app.$el.classList.add('is-header-scrolled');
    }

    return this;
  }

  /**
   * Toggle header navigation.
   */
  toggle() {
    if (this.$el.classList.contains('is-open')) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Open header navigation.
   */
  open() {
    this.$el.classList.add('is-open');
    window.app.$el.classList.add('overflow-hidden');
  }

  /**
   * Close header navigation.
   */
  close() {
    this.$el.classList.remove('is-open');
    window.app.$el.classList.remove('overflow-hidden');
  }

  /**
   * Initialize the component's behaviours.
   */
  scrolled({ y }) {
    if (y < 0) {
      return;
    }

    this.scrollHistory.push(y);
    this.scrollHistory.shift();

    if (this.scrollHistory[1] < this.scrollHistory[0]) {
      window.app.$el.classList.remove('is-scroll-bottom');
      window.app.$el.classList.add('is-scroll-top');
    } else if (this.scrollHistory[1] > this.scrollHistory[0]) {
      window.app.$el.classList.remove('is-scroll-top');
      window.app.$el.classList.add('is-scroll-bottom');
    }

    if (!y && window.app.$el.classList.contains('is-header-scrolled')) {
      window.app.$el.classList.remove('is-header-scrolled');
    } else if (y && !window.app.$el.classList.contains('is-header-scrolled')) {
      window.app.$el.classList.add('is-header-scrolled');
    }
  }

  /**
   * Destroy
   */
  destroy() {
    this.$refs.btn.removeEventListener('click', this.toggle);
  }
}


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
