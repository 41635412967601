/* eslint-disable class-methods-use-this */
import { Base } from '@studiometa/js-toolkit';
import LazyLoad from 'vanilla-lazyload';
// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Header from './components/header';
import PSCategoryProducts from './modules/ps_categoryproducts';
import PSCategoryTree from './modules/ps_categorytree';
import PSLanguageSelector from './modules/ps_languageselector';
import isDev from './utils/is-dev';

/**
 * Main App class.
 */
class App extends Base {
  /**
   * App config.
   * @return {Object}
   */
  get config() {
    return {
      log: isDev(),
      name: 'App',
      components: {
        Header,
        PSCategoryProducts,
        PSCategoryTree,
        PSLanguageSelector,
      },
    };
  }

  /**
   * Log a nice message when app is ready.
   *
   * @return {void}
   */
  mounted() {
    // eslint-disable-next-line dot-notation
    window['console'].log(
      '%c Made with ❤️ by Studio Meta %c https://www.studiometa.fr',
      'background-color: #1f1f1f; color: #fff; font-weight: 500; padding: 8px;',
      'background-color: #293933; color: #fff; font-weight: 500; padding: 8px;'
    );

    window.addEventListener('beforeunload', () => {
      if (window.$mainContainer) {
        window.$mainContainer.style.opacity = '0';
      }
    });

    this.$el.classList.add(`is-${this.getBrowser()}`);

    // Lazyload
    this.lazyload = new LazyLoad({
      // eslint-disable-next-line camelcase
      elements_selector: '.js-lazy',
      threshold: 600,
      // eslint-disable-next-line camelcase
      callback_enter: ($el) => {
        if ($el.classList.contains('js-lazy-error-placeholder')) {
          $el.lazyLoadPlaceholder = $el.src;
        }
      },
      // eslint-disable-next-line camelcase
      callback_loaded: ($el) => {
        const $container = $($el).closest('.js-lazy-container');
        if ($container.length) {
          $container.addClass('is-lazy-loaded');
        }
      },
      // eslint-disable-next-line camelcase
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      },
    });
  }

  /**
   * Get browser
   */
  getBrowser() {
    /* eslint-disable */
    return window.navigator.userAgent.indexOf('Chrome') > -1
        ? 'chrome'
        : window.navigator.userAgent.indexOf('Safari') > -1
        ? 'safari'
        : window.navigator.userAgent.indexOf('Opera') > -1
        ? 'opera'
        : window.navigator.userAgent.indexOf('Firefox') > -1
        ? 'firefox'
        : window.navigator.userAgent.indexOf('MSIE') > -1 ||
          window.navigator.userAgent.indexOf('Trident') > -1
        ? 'ie'
        : 'unknow';
    /* eslint-enable */
  }

  /**
   * Destroy
   */
  destroy() {
    // Lazyload
    this.lazyload.destroy();
    this.lazyload = null;
  }
}

const app = new App(document.documentElement);
window.app = app;
export default app;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
